export const ICON_NAMES = [
  'add',
  'arrowClock',
  'arrowDown',
  'arrowLeft',
  'arrowRight',
  'arrowUp',
  'arrowUpInCircle',
  'building',
  'chevronDown',
  'chevronLeft',
  'chevronRight',
  'chevronUp',
  'bin',
  'bold',
  'bulletedList',
  'bullHorn',
  'calendar',
  'centreAlign',
  'check',
  'checkInCircle',
  'checklist',
  'checkedCalendar',
  'circleFull',
  'circleLine',
  'clock',
  'cloudUpload',
  'cog',
  'comments',
  'compass',
  'copy',
  'cross',
  'crossInCircle',
  'delegate',
  'download',
  'edit',
  'exclamationCircle',
  'expiry',
  'externalLink',
  'eye',
  'eyeSlash',
  'file',
  'fileArchive',
  'fileCheck',
  'fileExcel',
  'fileHtml',
  'filePdf',
  'filePen',
  'filePowerpoint',
  'fileSearch',
  'fileText',
  'fileWithWriting',
  'fileWord',
  'fill',
  'filledCalendar',
  'filter',
  'gift',
  'grid',
  'gripDotsHorizontal',
  'gripDotsVertical',
  'help',
  'home',
  'hyperlink',
  'itemGroup',
  'image',
  'indent',
  'infoCircle',
  'information',
  'italic',
  'justify',
  'launch',
  'layerGroup',
  'leftAlign',
  'lightbulb',
  'lineChart',
  'lineHeight',
  'lock',
  'logout',
  'menu',
  'minus',
  'msExcelApp',
  'msExcelFile',
  'msPowerpointApp',
  'msPowerpointFile',
  'msWordApp',
  'msWordFile',
  'numberedList',
  'openBook',
  'options',
  'outdent',
  'paperClip',
  'paperPlane',
  'pause',
  'pauseCircle',
  'pen',
  'pencils',
  'person',
  'personWithPen',
  'personWithShield',
  'play',
  'plexusAi',
  'questionMark',
  'questionMarkCircle',
  'reply',
  'rightAlign',
  'search',
  'share',
  'signature',
  'stars',
  'strikethrough',
  'sync',
  'textSize',
  'thumbsDown',
  'thumbsUp',
  'underline',
  'undo',
  'unlink',
  'upload',
  'warning',
] as const;

export type IconName = (typeof ICON_NAMES)[number];

export interface SvgProps {
  className: string;
}
