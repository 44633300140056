import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { ComponentProps, StyleProps } from './StepSlider.types';

const BoxShadow =
  '0px 6px 13px 0px rgba(0, 0, 0, 0.12), 0px 0.5px 4px 0px rgba(0, 0, 0, 0.12)';

export const StyledSlider = withStyles(() => ({
  root: {
    color: '#3880ff',
    height: 4,
    padding: '40px 0 15px',
    "& .MuiSlider-mark[data-index='0']": {
      display: 'none',
    },
  },
  thumb: {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    boxShadow: BoxShadow,
    marginTop: -10,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: BoxShadow,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: BoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 6px)',
    top: -18,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
    '& span': {
      fontWeight: 700,
      fontSize: 12,
      fontFamily: 'Open Sans',
    },
  },
  track: (props: StyleProps) => ({
    height: 4,
    backgroundColor: props.trackColor || '#008DEF',
    borderRadius: 2,
  }),
  rail: (props: StyleProps) => ({
    borderRadius: 2,
    height: 4,
    opacity: 0.5,
    backgroundColor: props.railColor || '#ECECEE',
  }),
  mark: {
    backgroundColor: '#FFFFFF',
    height: 8,
    width: 2,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#FFFFFF',
  },
}))(
  ({
    railColor,
    trackColor,
    dataTestId,
    ...rest
  }: StyleProps & ComponentProps) => (
    <Slider data-testid={dataTestId} {...rest} />
  )
);
