import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle, getTextStyle } from '../../styles/text';
import { fontColor } from '../../styles';
import { StyleProps } from './Dropdown.types';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '5px',
    right: '16px',
  },
  button: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'row',
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: 'inherit',
    borderRadius: 2,
    padding: '3px 8px',
    alignItems: 'center',
  }),
  label: (props: StyleProps) => ({
    ...defaultTextStyle,
    ...getTextStyle({ weight: 'semiBold' }),
    color: props.isDisabled ? fontColor.disabled : fontColor.default,
    overflow: 'hidden',
    paddingLeft: '8px',
    paddingRight: '8px',
    textOverflow: 'ellipsis',
  }),
}));
