import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import InternalFieldWrapper from '../_internal/FieldWrapper';

import { ComponentProps } from './TextareaWithDropdown.types';
import { useStyles } from './TextareaWithDropdown.styles';
import Dropdown from './Dropdown';
import { SelectedOption } from './Dropdown.types';

const TextField: FC<ComponentProps> = ({
  autoFocus,
  dataTestId,
  errorMessage,
  hasError,
  id,
  inputRef,
  instructionText,
  isAutoResizing,
  isDisabled,
  isInline,
  isResizable,
  label,
  labelVariant = 'static',
  onBlur,
  onChange,
  onFocus,
  padding = 'md',
  placeholder,
  readOnly,
  secondaryLabel,
  secondaryLabelUrl,
  value,
  options,
}) => {
  const isEmpty = value.text === '';
  const classes = useStyles({
    isDisabled,
    isResizable,
    padding,
    isEmpty,
  });

  const [isActive, setIsActive] = useState(false);

  const thisRef = useRef<HTMLTextAreaElement | null>(null);
  const maxRows = 3;

  const isDynamicLabelMinimised = isActive || Boolean(value.text);
  const ref = inputRef ? inputRef : thisRef;
  const rows = useMemo(() => {
    return (isAutoResizing || isEmpty) && !isActive ? 1 : maxRows;
  }, [isAutoResizing, isResizable, isActive, isEmpty]);
  const styles = isAutoResizing
    ? { minHeight: 0, overflow: 'hidden' }
    : {
        padding: '11px 113px 11px 15px',
      };

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const handlePaddingTextarea = useCallback(() => {
    if (dropdownRef.current && thisRef.current) {
      const { style } = thisRef.current;
      const dropdownWidth = dropdownRef.current.clientWidth;
      const marginRight =
        dropdownWidth && dropdownWidth > 0 ? dropdownWidth + 21 : 115;
      style.padding = `11px ${marginRight}px 11px 15px`;
    }
  }, [dropdownRef.current, thisRef.current]);

  useEffect(() => {
    if (isResizable && thisRef && thisRef.current && value.text === '') {
      thisRef.current.style.height = 'auto';
    }
  }, [isResizable, value.text]);

  useEffect(() => {
    if (isAutoResizing && thisRef && thisRef.current) {
      const { style } = thisRef.current;
      style.height = '0px';
      const { scrollHeight } = thisRef.current;
      style.height = `${scrollHeight}px`;
    }
  }, [isAutoResizing, value.text]);

  useEffect(() => {
    handlePaddingTextarea();
  }, [value.selectedOption, handlePaddingTextarea]);

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur();
    }
    setIsActive(false);
  };

  const handleFocus = () => {
    handlePaddingTextarea();
    if (typeof onFocus === 'function') {
      onFocus();
    }
    setIsActive(true);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({
      text: event.currentTarget.value,
      selectedOption: value.selectedOption,
    });
  };

  const handleSelectChange = (selectedOption: SelectedOption) => {
    onChange({
      text: value.text,
      selectedOption,
    });
  };

  return (
    <InternalFieldWrapper
      errorMessage={errorMessage}
      hasError={hasError}
      instructionText={instructionText}
      isActive={isActive}
      isDisabled={isDisabled}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      label={label}
      labelVariant={labelVariant}
      placeholder={placeholder}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
    >
      <textarea
        autoFocus={autoFocus}
        className={classes.root}
        data-testid={dataTestId}
        disabled={isDisabled}
        id={id}
        onBlur={handleBlur}
        onChange={handleTextChange}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={ref}
        rows={rows}
        style={styles}
        value={value.text}
      />
      <Dropdown
        onChange={handleSelectChange}
        selectedOption={value.selectedOption}
        options={options}
        ref={dropdownRef}
        disabled={isDisabled}
      />
    </InternalFieldWrapper>
  );
};

export default TextField;
