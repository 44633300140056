import React from 'react';
import { DocParagraph, DocRow } from '@dev/components';
import { PlxsStepSlider } from '@plexus-ui/index';

export default () => {
  const [value, setValue] = React.useState(3);

  const handleChange = (event: any, value: number) => {
    setValue(value);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The <code>StepSlider</code> component is a slider with predefined
            steps.
          </DocParagraph>
        </DocRow>

        <div style={{ width: '400px' }}>
          <PlxsStepSlider
            dataTestId="weight-slider"
            value={value}
            step={1}
            marks
            min={1}
            max={5}
            valueLabelDisplay="on"
            onChange={handleChange}
          />
        </div>
      </DocRow>
    </DocRow>
  );
};
