import React, { FC } from 'react';
import { StyledSlider } from './StepSlider.styles';
import { ComponentProps } from './StepSlider.types';
import { color } from '../../styles';

const StepSlider: FC<ComponentProps> = ({
  dataTestId,
  defaultValue,
  step,
  marks = false,
  min,
  max,
  valueLabelDisplay,
  value,
  onChange,
}) => {
  return (
    <StyledSlider
      data-testid={dataTestId}
      railColor={color.neutralShade[800]}
      trackColor={color.brand}
      value={value}
      defaultValue={defaultValue}
      step={step}
      marks={marks}
      min={min}
      max={max}
      valueLabelDisplay={valueLabelDisplay}
      onChange={onChange}
    />
  );
};

export default StepSlider;
