import React, { FC } from 'react';

import { ComponentProps } from './Field.types';
import { FieldContext } from './Field.context';
import { useStyles } from './Field.styles';
import PlxsText from '../Text';
import ErrorMessage from '../_internal/FieldWrapper/_internal/ErrorMessage';

const Field: FC<ComponentProps> = ({
  children,
  errorMessage,
  hasError,
  label,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {label && (
        <PlxsText
          element="span"
          color={hasError ? 'urgent' : 'dark'}
          weight="semiBold"
        >
          {label}
        </PlxsText>
      )}
      <FieldContext.Provider value={{ hasError }}>
        {children}
      </FieldContext.Provider>
      {hasError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default Field;
